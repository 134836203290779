require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')
require('moment')
require("jquery-mask-plugin")

import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css"

import toastr from 'toastr/toastr';


$.jMaskGlobals.watchDataMask = true;